
import { Vue, Component, Watch } from 'vue-property-decorator';
import {apiUrl, appBaseDomain, assetsUrl, httpProtocol} from "@/env";
import { ref } from "vue";

@Component
export default class DefineComponent extends Vue {
  name: "pwdrecover";
  disableForm = false;

  isValid = false;
  inputValue = '';

  email_exist = false;
  data(): any {
    return {
      loading:false,
      unsend: true,
      error_content:"",
      error_display:false
    }
  }


  @Watch("inputValue")
  onInputValueChange(): void {
    if (this.$refs.emailInput.validate()) {
      this.isValid = true;
    }
    else this.isValid = false;
  }


  sendPwdRecover(): void {
    // console.log("sendPwdRecover");
    // const baseDomain = `${httpProtocol}://${appBaseDomain}/chgpwd/`;
    //
    // console.log("apiUrl", apiUrl);
    // console.log("assetsUrl", assetsUrl);
    // console.log("appBaseDomain", appBaseDomain);
    // console.log("email", this.$refs.emailInput.value);
    this.loading = true;
    this.error_display=false
    this.$store.dispatch("getPwdRecover", {
      email: this.$refs.emailInput.value,
      adrWeb: `${httpProtocol}://${appBaseDomain}/renew_pwd`,
      next: () => {
        console.log("next sendPwdRecover");
        this.unsend=false
      },
      errorNext: (error) => {
        this.loading = false;
        console.log("next sendPwdRecover Error");
        console.log(error);
        this.error_display=true
        if(error=="Not Found"){
          this.error_content="Aucun utilisateur existant avec cet email."
        }
        else{
          this.error_content="Une erreur est survenue."
        }
        },
    });
  }



}
